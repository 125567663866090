const color = {
  white: "#FFF",
  grayClearOne: "#D5D5D5",
  grayClear: "#868080",
  grayOne: "#878787",
  gray: "#5f5b5b",
  purple: "#B06FF1",
  purpleDark: "#9F5FDE",
  pink: "#F270FC",
  blue: "#336AE9",
  red: "#ED5A68",
  green: "#28A138",
  blueClear: "#dcefed",
  colorCard: "#F5F8FF",
  colorGrayElephant: "#5F738C",
  colorBorderCard: "#DBE6FF",
  colorsBaseProductNormal: "#3f45ea",
  colorsBaseProductNormalHover: "#2e33b7",
  colorsBaseInkNormal: "#252A31",
  colorsBaseInkLight: "#5F738C",
  colorsBaseProductNormalActive: "#336AE9",
  colorNavyBlue: "#24234F",
  colorCardOrange: "#FCAD09",
  colorsBaseProductLightActive: "#ccd0ee",
  colorsBaseCloudNormal: "#EFF2F5",
  colorsBaseProductDarkHover: "#1C1F7F",
  colorsBaseInkNormalActive: "#0B0C0F",
  colorsBaseInkLighterActive: "#94a8be",
  colorsBaseInkLightHover: "#52647a",
  colorsBaseProductLighter: "#fafafe",
};

export default color;
